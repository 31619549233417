var Cameraland = Cameraland || {};

Cameraland.BackToTop = class {
    constructor(options) {
        this.showButton = this.showButton.bind(this);
        this.scrollTop = this.scrollTop.bind(this);
        this.showButton = this.showButton.bind(this);

        this.options = $j.extend({}, {
            btnSelector: '#back-to-top'
        }, options);


        this.init();
        this.showButton();
        this.scrollTop();
    }

    init()
    {
        $j('body,html').append('<div id="back-to-top"><i class="ic-chevron-up"></i></div>');
    }

    showButton() {
        const scrollButton = $j(this.options.btnSelector);
        let timer;

        $j(window).scroll(() => {
            if (timer) {
                window.clearTimeout(timer);
            }

            timer = window.setTimeout(function () {
                if ($j(this).scrollTop() > 300) {
                    scrollButton.addClass('show');
                } else {
                    scrollButton.removeClass('show');
                }
            }, 50);
        });
    }

    scrollTop() {
        const scrollButton = $j(this.options.btnSelector);

        scrollButton.on('click', (e) => {
            e.preventDefault();
            $j('html, body').animate({
                scrollTop: 0
            }, 500);
        });
    }
};

$j(function () {
    new Cameraland.BackToTop();
});